import React from 'react'

function TagFilter({id, content, filter, handleClick}) {
  return (
    <span className='tag-filter'>
        {content} - {filter}
        <button onClick={() => handleClick(id)}></button>
    </span>
  )
}

export default TagFilter