import React, { useEffect, useState } from "react";
import Card from "../common/Card/Card";
import Button from "../common/Button";
import Slider from "react-slick";
import Loader from "../common/Loader";
import ContentEmpty from "../common/ContentEmpty";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../assets/js/dataHelpers";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LIMIT = 6;
const page = 0;

function Actions() {
  const [actions, setActions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const data = await fetchData(LIMIT, page);
      data.registros.length ? setActions(data.registros) : setActions(false);
      setIsLoading(false);
    })();
  }, []);

  // Navigation Button
  const navigate = useNavigate();
  function handleClick(e) {
    e.preventDefault();

    navigate("/actions-search");
  }

  // Settings Slider Actions
  const settings = {
    dots: true,
    infinite: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section id="actions" className="actions">
      {isLoading && <Loader />}

      {!isLoading && !actions && (
        <ContentEmpty
          title={"Ainda estamos sem ações realizadas :("}
          content={"Em breve informaremos as nossas novidades."}
        />
      )}

      {!isLoading && actions && (
        <div className="actions-content container">
          <div className="actions-content-slider">
            <Slider {...settings}>
              {actions.map((action) => {
                return <Card {...action} key={action.id} type={"action"} />;
              })}
            </Slider>
          </div>
          <div className="actions-content-text">
            <p>
              Nossas ações se desenvolvem através de reuniões regulares em
              formato de assembleia, grupos de trabalho, comissões, parcerias,
              geração de renda através da comercialização de produtos e
              prestação de serviços.
            </p>
          </div>

          <Button
            buttonClass="btn btn-secondary--dark actions-content-btn"
            content="ver todas"
            handleClick={handleClick}
          />
        </div>
      )}

      <h2 className="actions-title">Ações</h2>
    </section>
  );
}

export default Actions;
