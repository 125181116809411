let areasList = [];

export async function fetchData(limit, page) {

    const url = `${process.env.REACT_APP_URL_BACKEND}/acoes?limit=${limit}&page=${page}`;

    try {
        const response = await fetch(url);
        const responseData = await response.json();

        return responseData;
    } catch (error) {
        console.log(error);
    }
}

export async function fetchDataEES(status, limit, page) {

    const url = `${process.env.REACT_APP_URL_BACKEND}/eess?status=${status}&limit=${limit}&page=${page}`;

    try {
        const response = await fetch(url);
        const responseData = await response.json();

        return responseData;
    } catch (error) {
        console.log(error);
    }
}

export function getListName(partners, type) {
    const list = partners.map((partner) =>
        partner[type] ? partner.nome : null
    );
    return list;
}

export function getMonthName(month) {
    const monthsName = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

    return {
        id: month,
        nome: monthsName[month - 1]
    }
}

export function getNameAreas(id) {
    const area = areasList.find(area => area.id === id );

    return area.nome;
}

export async function getYears(type) {
    const url = `${process.env.REACT_APP_URL_BACKEND}/${type}/anos`;

    try {
        const response = await fetch(url);
        const responseData = await response.json();

        return responseData;
    } catch (error) {
        console.log(error);
    }
}

export async function getMonths(type, year) {
    const url = `${process.env.REACT_APP_URL_BACKEND}/${type}/meses?ano=${year}`;

    try {
        const response = await fetch(url);
        const responseData = await response.json();

        return responseData;
    } catch (error) {
        console.log(error);
    }
}

export async function getStates(type) {
    const url = `${process.env.REACT_APP_URL_BACKEND}/${type}/estados`;

    try {
        const response = await fetch(url);
        const responseData = await response.json();

        return responseData;
    } catch (error) {
        console.log(error);
    }
}

export async function getCities(type, state) {
    const url = `${process.env.REACT_APP_URL_BACKEND}/${type}/cidades?estado=${state}`;

    try {
        const response = await fetch(url);
        const responseData = await response.json();

        return responseData;
    } catch (error) {
        console.log(error);
    }
}

export async function getAreas() {
    const url = `${process.env.REACT_APP_URL_BACKEND}/areas`;

    try {
        const response = await fetch(url);
        const responseData = await response.json();

        responseData && responseData.registros.forEach( item => {
            areasList.push(item);
        })

        return responseData;
    } catch (error) {
        console.log(error);
    }
}


export async function filterActions(page = 0, limit = 6, filters) {
    if (!filters) {
        return;
    }

    const url = `${process.env.REACT_APP_URL_BACKEND}/acoes?page=${page}&limit=${limit}&ano=${filters.ano}&mes=${filters.mes}&estado=${filters.estado}&cidade=${filters.cidade}`;

    try {
        const response = await fetch(url);
        const responseData = await response.json();

        console.log(responseData)

        return responseData;
    } catch (error) {
        console.log(error);
    }
}


export async function filterEESS(status, page, limit, filters) {

    const url = `${process.env.REACT_APP_URL_BACKEND}/eess?status=${status}&page=${page}&limit=${limit}&estado=${filters.estado}&cidade=${filters.cidade}&area=${filters.area}`;
    console.log(url)
    try {
        const response = await fetch(url);
        const responseData = await response.json();

        console.log(responseData)

        return responseData;
    } catch (error) {
        console.log(error);
    }
}

export function noFilters(obj) {
    return Object.values(obj).every(value => !value);
}