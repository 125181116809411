import React, { useState, useEffect, useRef, useContext } from "react";
import Card from "../common/Card/Card";
import Select from "../common/Select";
import Button from "../common/Button";
import Loader from "../common/Loader";
import ContentEmpty from "../common/ContentEmpty";
import ModalAction from "../common/Modal/ModalAction";
import { HeaderContext } from "../HeaderContext";
import {fetchData, filterActions, getCities, getMonthName, getMonths, getStates, getYears, noFilters, } from "../../assets/js/dataHelpers";
import { motion } from "framer-motion";
import TagFilter from "../common/TagFilter";
const LIMIT = 6;
let page = 0;

function ActionsHistory() {
  const [actions, setActions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState("");
  const [years, setYears] = useState(null);
  const [months, setMonths] = useState(null);
  const [cities, setCities] = useState(false);
  const [states, setStates] = useState(null);
  const [noResults, setNoResults] = useState(false);
  const [filterActived, setFilterActived] = useState(false);
  const sliderRef = useRef();
  const globalHeader = useContext(HeaderContext);
  const body = document.querySelector("html");
  const [filters, setfilters] = useState({
    ano: "",
    mes: "",
    estado: "",
    cidade: "",
  });

  globalHeader.setHeaderState(false);

  // Apply filters
  const handleFilterChange = (event) => {
    const { id, value } = event.target;

    setFilterActived(true);
    setDisabled(false);
    setIsLoading(true);

    switch (id) {
      case "ano":
        (async () => {
          let dataMonths = await getMonths("acoes", value);
          dataMonths = Array.from(new Set(dataMonths.map(JSON.stringify)))
            .map(JSON.parse)
            .map((item) => item.mes);
          const monthList = dataMonths
            .sort()
            .map((month) => getMonthName(month));
          setMonths(monthList);
          setfilters((prevfilters) => {
            const updatedfilters = {
              ...prevfilters,
              [id]: value,
              mes: "",
            };

            const dataFiltered = filterActions(0, 6, updatedfilters);
            dataFiltered.then((response) => {
              response.registros.length
                ? setActions(response.registros)
                : setActions(false);
              setIsLoading(false);
            });
            return updatedfilters;
          });
        })();
        break;

      case "estado":
        (async () => {
          const dataCities = await getCities("acoes", value);
          setCities(dataCities);
          setfilters((prevfilters) => {
            const updatedfilters = {
              ...prevfilters,
              [id]: value,
              cidade: "",
            };

            const dataFiltered = filterActions(0, 6, updatedfilters);
            dataFiltered.then((response) => {
              response.registros.length
                ? setActions(response.registros)
                : setActions(false);
              setIsLoading(false);
            });
            return updatedfilters;
          });
        })();
        break;

      default:
        setfilters((prevfilters) => {
          const updatedfilters = {
            ...prevfilters,
            [id]: value,
          };

          const dataFiltered = filterActions(0, 6, updatedfilters);
          dataFiltered.then((response) => {
            response.registros.length
              ? setActions(response.registros)
              : setActions(false);
            setIsLoading(false);
          });
          return updatedfilters;
        });
        break;
    }
  };

  // Functions OPEN e CLOSE MODAL
  function openModal(e) {
    e.preventDefault();

    //Get isMobile
    const windowWidth = window.innerWidth;
    const slideNumber = e.currentTarget.getAttribute("id");

    if (windowWidth < 1024) return;

    sliderRef.current.slickGoTo(slideNumber);
    setIsOpen("is-open");
    body.style.overflow = "hidden";
  }

  function closeModal(e) {
    e.preventDefault();
    setIsOpen("");
    body.style.overflow = "auto";
  }

  // Getting More Action on the Click!
  function getMoreActions() {
    page++;
    setIsLoading(true);
    const dataActions = filterActived
      ? filterActions(page, LIMIT, filters)
      : fetchData(LIMIT, page);

    dataActions.then((response) => {
      response.registros.length <= LIMIT
        ? setDisabled(true)
        : setDisabled(false);

      const updateActions = actions.concat(response.registros);
      setIsLoading(false);
      setActions(updateActions);
    });
  }

  // Removing the filters
  function removeFilter(filter) {
    setIsLoading(true);
    setfilters((prevfilters) => {
      const updatedfilters = {
        ...prevfilters,
        [filter]: "",
      };

      const dataFiltered = filterActions(0, 6, updatedfilters);
      dataFiltered.then((response) => {
        response.registros.length
          ? setActions(response.registros)
          : setActions(false);
        setIsLoading(false);

        // Disable Month and City Selects
        if(filter === 'ano' || filter === 'estado') {
          setCities('');
          setMonths('');
        }

        // Hide the filter bar
        const emptyFilters = noFilters(updatedfilters);
        if(emptyFilters) setFilterActived(false);
      });
      return updatedfilters;
    });
  }

  // Remove all filter
  function removeAllFilter() {
    setIsLoading(true);
    setfilters(() => {
      const updatedfilters = {
        ano: "",
        mes: "",
        estado: "",
        cidade: "",
      };

      const dataFiltered = filterActions(0, 6, updatedfilters);
      dataFiltered.then((response) => {
        response.registros.length
          ? setActions(response.registros)
          : setActions(false);
        setIsLoading(false);
        setCities('');
        setMonths('');
        setFilterActived(false);
      });
      return updatedfilters;
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      page = 0;
      setIsLoading(true);
      const dataActions = await fetchData(LIMIT, page);
      const dataStates = await getStates("acoes");
      let dataYears = await getYears("acoes");
      // let dataMonths = await getMounths("acoes");

      // Getting the years, months and eliminate the duplicate values
      dataYears = Array.from(new Set(dataYears.map(JSON.stringify)))
        .map(JSON.parse)
        .map((item) => item.ano);

      // Setting data
      !dataActions.registros.length ? setNoResults(true) : setNoResults(false);
      dataActions.registros.length
        ? setActions(dataActions.registros)
        : setActions(false);
      setIsLoading(false);
      setYears(dataYears);
      setStates(dataStates);
    })();
  }, []);

  return (
    <motion.section
      id="actions-history"
      className={`actions-history`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className="actions-history-content">
        <h1 className="actions-history-content--title">Ações</h1>
        <p className="actions-history-content--text container">
          Nossas ações se desenvolvem através de reuniões regulares em formato
          de assembleia, grupos de trabalho, comissões, parcerias, geração de
          renda através da comercialização de produtos e prestação de serviços.
        </p>
      </div>
      <form
        className={`actions-history-form container ${
          noResults ? "d-none" : ""
        }`}
      >
        <div className="actions-history-form-select">
          <Select
            name={"ano"}
            label={"Ano"}
            data={years}
            handleChange={handleFilterChange}
          />
        </div>
        <div className="actions-history-form-select">
          <Select
            name={"mes"}
            label={"Mês"}
            data={months}
            handleChange={handleFilterChange}
          />
        </div>
        <div className="actions-history-form-select">
          <Select
            name={"estado"}
            label={"Estado"}
            data={states}
            handleChange={handleFilterChange}
          />
        </div>
        <div className="actions-history-form-select">
          <Select
            name={"cidade"}
            label={"Cidade"}
            data={cities}
            handleChange={handleFilterChange}
          />
        </div>
      </form>

      {filterActived && (
        <div className="filters">
          {filters.ano && (
            <TagFilter
              id={"ano"}
              content={"ano"}
              filter={filters.ano}
              handleClick={removeFilter}
            />
          )}
          {filters.mes && (
            <TagFilter
              id={"mes"}
              content={"mês"}
              filter={getMonthName(filters.mes).nome}
              handleClick={removeFilter}
            />
          )}
          {filters.estado && (
            <TagFilter
              id={"estado"}
              content={"estado"}
              filter={filters.estado}
              handleClick={removeFilter}
            />
          )}
          {filters.cidade && (
            <TagFilter
              id={"cidade"}
              content={"cidade"}
              filter={filters.cidade}
              handleClick={removeFilter}
            />
          )}

          <button onClick={removeAllFilter}>Remove Todos</button>
        </div>
      )}

      {isLoading && <Loader />}

      {!isLoading && !actions && (
        <ContentEmpty
          title={"Sem resultado no nosso histórico :("}
          content={"Em breve, estaremmos atualizando as nossas novidades."}
        />
      )}

      {actions && (
        <div
          className={`actions-history-cards ${disabled ? "no-results" : ""}`}
        >
          {actions.map((action, index) => {
            return (
              <Card
                {...action}
                index={index}
                cardClass={"bg-gray"}
                key={action.nome}
                type={"action"}
                openModal={openModal}
              />
            );
          })}
        </div>
      )}

      {!isLoading && actions && (
        <Button
          buttonClass={`btn btn-secondary--dark actions-history-btn ${
            disabled || noResults ? "d-none" : ""
          }`}
          content={"Ver mais"}
          handleClick={getMoreActions}
        />
      )}

      <ModalAction
        isOpen={isOpen}
        sliderRef={sliderRef}
        closeModal={closeModal}
        actions={actions}
      />
    </motion.section>
  );
}

export default ActionsHistory;
