import React from "react";

function ModalPartners({ isOpen, closeModal }) {
  return (
    <div
      id="modal"
      className={`modal ${isOpen}`}
      tabIndex="-1"
      role="dialog"
      aria-hidden={!isOpen ? true : false}
    >
      <div className="modal-container" role="document">
        <div className="modal-header">
          <h2 className="modal-header-title">como apoiar <br/> e financiar</h2>
          <button
            className="modal-header-btn"
            aria-label="Fechar Modal"
            onClick={closeModal}
          ></button>
        </div>
        <div className="modal-content">
          <p>Apoie a nossa Rede!</p>
          <p>Você pode colaborar de diversas formas:</p>

          <ul>
            <li>Parcerias </li>
            <li>Formações </li>
            <li>Financiamento</li>
            <li>Trabalho voluntário</li>
            <li>Projetos</li>
          </ul>
          
          <p>
            O seu apoio proporcionará geração de renda, autonomia e cidadania
            para pessoas em vulnerabilidade psicossocial.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ModalPartners;
