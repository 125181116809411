import React, { useState, useEffect, useRef, useContext } from "react";
import Card from "../common/Card/Card";
import Select from "../common/Select";
import Button from "../common/Button";
import Loader from "../common/Loader";
import ContentEmpty from "../common/ContentEmpty";
import ModalShowCase from "../common/Modal/ModalShowCase";
import { HeaderContext } from "../HeaderContext";
import { fetchDataEES, filterEESS, getNameAreas, getAreas, getCities, getStates, noFilters } from "../../assets/js/dataHelpers";
import { motion } from "framer-motion";
import TagFilter from "../common/TagFilter";
const LIMIT = 6;
let page = 0;

function ShowCaseHistory() {
  const [showcases, setShowCases] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState("");
  const [cities, setCities] = useState(false);
  const [states, setStates] = useState(null);
  const [areas, setAreas] = useState(null);
  const [noResults, setNoResults] = useState(false);
  const [filterActived, setFilterActived] = useState(false);
  const sliderRef = useRef();
  const globalHeader = useContext(HeaderContext);
  const body = document.querySelector("html");
  const [filters, setfilters] = useState({
    estado: "",
    cidade: "",
    area: ""
  });
  
  globalHeader.setHeaderState(true);

  const handleFilterChange = (event) => {
    let { id, value } = event.target;

    setFilterActived(true);
    setDisabled(false);
    setIsLoading(true);

    switch (id) {
      case "estado":
        (async () => {
          const dataCities = await getCities("eess", value);
          setCities(dataCities);
          setfilters((prevfilters) => {
            const updatedfilters = {
              ...prevfilters,
              [id]: value,
              cidade: "",
            };

            const dataFiltered = filterEESS(true, page, LIMIT, updatedfilters);
            dataFiltered.then((response) => {
              response.registros.length
                ? setShowCases(response.registros)
                : setShowCases(false);
              setIsLoading(false);
            });
            return updatedfilters;
          });
        })();
        break;

      default:
        setfilters((prevfilters) => {
          if(id === 'area') value = Number(value);

          const updatedfilters = {
            ...prevfilters,
            [id]: value,
          };

          const dataFiltered = filterEESS(true, page, LIMIT, updatedfilters);
          dataFiltered.then((response) => {
            response.registros.length
              ? setShowCases(response.registros)
              : setShowCases(false);
            setIsLoading(false);
          });
          return updatedfilters;
        });
        break;
    }
  };

  function openModal(e) {
    e.preventDefault();

    //Get isMobile
    const windowWidth = window.innerWidth;
    const slideNumber = e.currentTarget.getAttribute("id");

    if (windowWidth < 1024) return;

    sliderRef.current.slickGoTo(slideNumber);
    setIsOpen("is-open");
    body.style.overflow = "hidden";
  }

  function closeModal(e) {
    e.preventDefault();
    setIsOpen("");
    body.style.overflow = "auto";
  }

  // Getting More EESS with onClick!
  function getMoreActions() {   
    page++;
    const dataEES = filterActived ? filterEESS(true, page, LIMIT, filters) : fetchDataEES(true, LIMIT, page);
    dataEES.then(response =>{
      response.registros.length <= LIMIT ? setDisabled(true) : setDisabled(false);
      const updateEES = showcases.concat(response.registros);

      setShowCases(updateEES);
    })      
  }

  // Removing the filters
  function removeFilter(filter) {
    setIsLoading(true);
    setfilters((prevfilters) => {
      const updatedfilters = {
        ...prevfilters,
        [filter]: "",
      };

      const dataFiltered = filterEESS(true, page, LIMIT, updatedfilters);
      dataFiltered.then((response) => {
        response.registros.length
          ? setShowCases(response.registros)
          : setShowCases(false);
        setIsLoading(false);

        // Disable Month and City Selects
        if(filter === 'estado') setCities('');

        // Hide the filter bar
        const emptyFilters = noFilters(updatedfilters);
        if(emptyFilters) setFilterActived(false);
      });
      return updatedfilters;
    });
  }

  // Remove all filter
  function removeAllFilter() {
    setIsLoading(true);
    setfilters(() => {
      const updatedfilters = {
        estado: "",
        cidade: "",
        area: "" 
      };

      const dataFiltered = filterEESS(true, page, LIMIT, updatedfilters);
      dataFiltered.then((response) => {
        response.registros.length
          ? setShowCases(response.registros)
          : setShowCases(false);
        setIsLoading(false);
        setCities('');
        setFilterActived(false);
      });
      return updatedfilters;
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      page = 0;
      setIsLoading(true);
      const dataEES = await fetchDataEES(true, LIMIT, page);
      const dataAreas = await getAreas();
      const dataStates = await getStates('eess');

      // Setting data
      !dataEES.registros.length ? setNoResults(true) : setNoResults(false);
      dataEES.registros.length ? setShowCases(dataEES.registros) : setShowCases(false);
      setAreas(dataAreas.registros);
      setStates(dataStates);
      setIsLoading(false);
    })();
  }, []);

  return (
    <motion.section
      id="showcase-history"
      className={`showcase-history`}
      initial={{  opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className="showcase-history-content">
        <h1 className="showcase-history-content--title">Vitrine</h1>
        <p className="showcase-history-content--text container">
          Esta é a vitrine da rede, aqui você pode conhecer e localizar o
          Empreendimento Econômico Solidário - EES mais próximo de você e seus
          produtos e/ou serviços de seu interesse. Conheça abaixo:
        </p>
      </div>
      <form
        className={`showcase-history-form container ${
          noResults ? "d-none" : ""
        }`}
      >
        <div className="showcase-history-form-select">
          <Select name={"estado"} label={"Estado"} data={states} handleChange={handleFilterChange}/>
        </div>
        <div className="showcase-history-form-select">
          <Select name={"cidade"} label={"Cidade"} data={cities}  handleChange={handleFilterChange}/>
        </div>
        <div className="showcase-history-form-select">
          <Select name={"area"} label={"Área de atuação"} data={areas} handleChange={handleFilterChange}/>
        </div>
      </form>

      {filterActived && (
        <div className="filters">
          {filters.estado && (
            <TagFilter
              id={"estado"}
              content={"estado"}
              filter={filters.estado}
              handleClick={removeFilter}
            />
          )}
          {filters.estado && filters.cidade && (
            <TagFilter
              id={'cidade'}
              content={"cidade"}
              filter={filters.cidade}
              handleClick={removeFilter}
            />
          )}
          {filters.area && (
            <TagFilter
              id={'area'}
              content={"área"}
              filter={getNameAreas(filters.area)}
              handleClick={removeFilter}
            />
          )}

          <button onClick={removeAllFilter}>Remover Todos</button>
        </div>
      )}

      {isLoading && <Loader />}

      {!isLoading && !showcases && (
        <ContentEmpty
          title={"Sem resultado no nosso histórico :("}
          content={"Em breve, estaremos atualizando as nossas novidades."}
        />
      )}

      {showcases && (
        <div
          className={`showcase-history-cards ${disabled ? "no-results" : ""}`}
        >
          {showcases.map((showcase, index) => {
            return (
              <Card
                {...showcase}
                index={index}
                key={showcase.nome}
                type={"showcase"}
                openModal={openModal}
              />
            );
          })}
        </div>
      )}

      {!isLoading && showcases && (
        <Button
          buttonClass={`btn btn-secondary--dark showcase-history-btn ${
            disabled || noResults ? "d-none" : ""
          }`}
          content={"Ver mais"}
          handleClick={getMoreActions}
        />
      )}

      <ModalShowCase
        isOpen={isOpen}
        sliderRef={sliderRef}
        closeModal={closeModal}
        showcases={showcases}
      />
    </motion.section>
  );
}

export default ShowCaseHistory;
