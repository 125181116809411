import React from 'react';

function Select({name, label, data, handleChange}) {
  
  const dataList = data && data.map(item =>{ 
    if(item.nome){
      return {
        value: item.id,
        label: item.nome
      };
    } else {
      return {
        value: item,
        label: item
      }
    }
  });


  return (
    <select name={name} id={name} value={""} disabled={!data} onChange={handleChange}>
        <option value="" hidden selected>{label}</option>
        {dataList && dataList.map(data =>{
          return <option value={data.value}>{data.label}</option>
        })}        
    </select>
  )
}

export default Select